// @flow

import type { SellActions } from './types';
import * as sellTypes from './constants';

export const sellProcess = (): SellActions => ({
  type: sellTypes.SELL_REQUEST,
  payload: null,
});

export const sellProcessSuccess = (): SellActions => ({
  type: sellTypes.SELL_SUCCESS,
  payload: null,
});

export const sellProcessFail = (): SellActions => ({
  type: sellTypes.SELL_FAIL,
  payload: null,
});

export const verifySell = (): SellActions => ({
  type: sellTypes.SELL_VERIFY,
  payload: null,
});

export const verifySellSuccess = (): SellActions => ({
  type: sellTypes.SELL_VERIFY_SUCCESS,
  payload: null,
});

export const verifySellGoBack = (): SellActions => ({
  type: sellTypes.SELL_VERIFY_FAIL,
  payload: null,
});

export const sellShowModal = (): SellActions => ({
  type: sellTypes.SELL_SHOW_MODAL,
  payload: null,
});

export const sellCloseModal = (): SellActions => ({
  type: sellTypes.SELL_CLOSE_MODAL,
  payload: null,
});
