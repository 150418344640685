export default {
  en: {
    'Total.Token.Sold': 'Total Token Sold',
    'Total.Token.Balance': 'Total Token Balance',
    'Total.Holders': 'Total Holders',
  },
  ch: {
    'Total.Token.Sold': '总售出货币',
    'Total.Token.Balance': '货币总余额',
    'Total.Holders': '持币人数',
  },
};
