// @flow

import type { ConnectActions, typeConnectSuccess } from './types';
import * as connectTypes from './constants';

export const connectWallet = (): ConnectActions => ({
  type: connectTypes.CONNECT_REQUEST,
  payload: null,
});

export const connectWalletSuccess = (address: typeConnectSuccess): ConnectActions => ({
  type: connectTypes.CONNECT_SUCCESS,
  payload: address,
});

export const connectWalletFail = (): ConnectActions => ({
  type: connectTypes.CONNECT_FAIL,
  payload: null,
});

export const closeModalConnect = (): ConnectActions => ({
  type: connectTypes.CLOSE_MODAL_CONNECT,
  payload: null,
});

export const showModalConnect = (): ConnectActions => ({
  type: connectTypes.SHOW_MODAL_CONNECT,
  payload: null,
});

export const showModalDownloadMetaMask = (): ConnectActions => ({
  type: connectTypes.SHOW_MODAL_DOWNLOAD_METAMASK,
  payload: null,
});
