/* eslint-disable no-return-assign */
// @flow
import React, { useState, useRef, type AbstractComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';

import numeral from 'numeral';
import Button from 'components/Button';
import ItemAccount from 'components/ItemAccount';

import {
  people,
  cash,
  checkmarkCircle,
  time,
  person,
  copy,
  // share
} from 'assets/images';

import styles from './ListBalance.module.scss';

// OwnProps refers to the props that were passed down by the parent.
type OwnProps = {||};

// Props created from OwnProps plus the props passed in by mapStateToProps and mapDispatchToProps
type Props = {|
  ...OwnProps,
  balance: string,
  dailyInterest: string,
  address: string,
  myReferrals: Array<string>,
  t: Function,
|};

export const ListBalanceComponent = ({
  balance,
  dailyInterest,
  address,
  myReferrals,
  t,
}: Props) => {
  const [isShowAddress, setShowAddress] = useState(false);

  let fooRef = null;
  // let fooRef2 = null;

  const countRef = useRef(isShowAddress);
  countRef.current = isShowAddress;

  const copyAddress = () => {
    setShowAddress(true);
    ReactTooltip.show(fooRef);
    setTimeout(() => {
      setShowAddress(false);
      ReactTooltip.hide(fooRef);
    }, 3000);
  };

  // const handleClaim = () => {

  // };

  return (
    <div className={styles.body}>
      <ItemAccount
        img={person}
        title={t('My.ID')}
        ref={(ref) => fooRef = ref}
        value={(
          <div
            className={styles.containerAddress}
          >
            <>
              <div className={styles.value}>
                {address ? `${address.slice(0, 4)}...${address.slice(address.length - 4, address.length)}` : '-'}
              </div>
              <CopyToClipboard text={address || ''}>
                <button
                  type="button"
                  className={styles.share}
                  onClick={copyAddress}
                >
                  <img src={copy} alt="copy" className={styles.img} />
                </button>
              </CopyToClipboard>
            </>

          </div>
        )}
        button={(
          <>
            <CopyToClipboard text={address || ''}>
              <Button
                color="primary"
                isSmall
                isCopy
                onClick={copyAddress}
                disabled={isShowAddress}
              >
                {t('Copy')}
              </Button>
            </CopyToClipboard>
          </>
        )}
      />
      <div className={styles.containerCopied}>
        <p ref={(ref) => fooRef = ref} data-tip={t('Copied')} className={styles.copied} />
      </div>
      <div className={styles.line} />

      <ItemAccount
        img={cash}
        title={t('My.Token.Balance')}
        value={`${numeral(balance).add(dailyInterest || 0).format('0,0.0000')}  ESTT`}
      />

      <div className={styles.line} />

      <ItemAccount
        img={time}
        title={t('Pending.Interest')}
        value={`${numeral(parseFloat(dailyInterest || 0).toFixed(6)).format('0,0.0000')}  ESTT`}
      />

      <div className={styles.line} />

      <ItemAccount
        img={checkmarkCircle}
        title={t('Available.Balance')}
        // value={`${numeral(balance).format('0,0.000')}  ESTT`}
        value={(
          <div className={styles.containerAvailableBalance}>
            <>
              <div className={styles.value}>
                {`${numeral(balance || 0).format('0,0.0000')}  ESTT`}
              </div>
              {/* <button type="button" className={styles.claim} onClick={handleClaim}>
                <img src={share} alt="copy" className={styles.img} />
              </button> */}
            </>
          </div>
        )}
        // button={(
        //   <Button color="outline" isSmall onClick={handleClaim}>
        //     Claim
        //   </Button>
        // )}
      />

      <div className={styles.activeReferral}>
        <div className={styles.line} />

        <ItemAccount
          img={people}
          title={t('Active.Referral')}
          value={myReferrals.length}
        />
      </div>

      <ReactTooltip className={styles.tooltip} />

    </div>
  );
};

const mapStateToProps = (state) => ({
  balance: state.balance.balance,
  dailyInterest: state.balance.dailyInterest,
  address: state.connect.address,
  myReferrals: state.connect.myReferrals,

});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch,
);

export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('listBalance')(ListBalanceComponent)): AbstractComponent<OwnProps>);
