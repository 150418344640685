// @flow
import React, { Component, type AbstractComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import numeral from 'numeral';
import { withTranslation } from 'react-i18next';
import * as actionsBalance from 'store/Balance/actions';

import styles from './BalanceTotal.module.scss';

// OwnProps refers to the props that were passed down by the parent.
type OwnProps = {||};

// Props created from OwnProps plus the props passed in by mapStateToProps and mapDispatchToProps
type Props = {|
  ...OwnProps,
  totalSupply: string,
  sold: string,
  holdersCounter: number,
  t: Function,
  getBalance: Function,
|};

export class BalanceTotalComponent extends Component<Props> {
  componentDidMount() {
    const { getBalance } = this.props;
    getBalance();
  }

  render() {
    const {
      totalSupply, sold, holdersCounter, t,
    } = this.props;
    return (
      <div className={styles.containerBalance}>
        <div className={styles.containerLeft}>
          <div className={styles.title}>
            {t('Total.Token.Sold')}
          </div>
          <div className={styles.value}>
            {numeral(sold).format('0,0')}
            <div className={styles.symbol}>
              ESTT
            </div>
          </div>
          <div className={styles.title}>
            {t('Total.Token.Balance')}
          </div>
          <div className={styles.valueBalance}>
            {numeral(totalSupply).format('0,0')}
            <div className={styles.symbolBalance}>
              ESTT
            </div>
          </div>
        </div>
        <div className={styles.containerRight}>
          <div className={styles.holder}>
            <div className={styles.labelHolder}>
              {t('Total.Holders')}
            </div>
            <div className={styles.valueHolder}>
              {holdersCounter}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  totalSupply: state.balance.totalSupply,
  sold: state.balance.sold,
  holdersCounter: state.connect.holdersCounter,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getBalance: actionsBalance.getBalance,
  },
  dispatch,
);

export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('balanceTotal')(BalanceTotalComponent)): AbstractComponent<OwnProps>);
