// @flow
import type { Saga } from 'redux-saga';
import { all } from 'redux-saga/effects';

// sagas
import connectSaga from './Connect/sagas';
import balanceSaga from './Balance/sagas';
import buySaga from './Buy/sagas';
import sellSaga from './Sell/sagas';
import allowSaga from './Allow/sagas';

export default function* root(): Saga<void> {
  yield all([
    connectSaga(),
    balanceSaga(),
    buySaga(),
    sellSaga(),
    allowSaga(),
  ]);
}
