// @flow
import web3 from 'web3';
import * as balanceTypes from './constants';
import type { BalanceActions } from './types';

type State = {
  amount: string,
  referrerId: string,
  isValidReferredId: boolean,
};

const referrerIdDefault = new URL(document.location.href).searchParams.get('referrer') || '';

const initialState: State = {
  amount: '0',
  referrerId: referrerIdDefault,
  isValidReferredId: web3.utils.isAddress(referrerIdDefault),
};

export default (state: State = initialState, action: BalanceActions): State => {
  switch (action.type) {
    // place case statements here
    case balanceTypes.BALANCE_SUCCESS:
      return {
        ...state,
        balance: action.payload.balance,
        balanceUsdt: action.payload.balanceUsdt,
        totalSupply: action.payload.totalSupply,
        balanceExchange: action.payload.balanceExchange,
        dailyInterest: action.payload.dailyInterest,
        sold: action.payload.sold,
      };

    case balanceTypes.SET_AMOUNT_FOR_SELL_BUY:
      return {
        ...state,
        amount: action.payload,
      };

    case balanceTypes.SET_REFERRER_ID:
      return {
        ...state,
        referrerId: action.payload,
        isValidReferredId: web3.utils.isAddress(action.payload),
      };

    default:
      return state;
  }
};
