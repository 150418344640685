export default {
  en: {
    'Invitation.Link': 'Invitation Link',
    Copied: 'Copied',
  },
  ch: {
    'Invitation.Link': '邀请链接',
    Copied: '已拷贝',
  },
};
