/* eslint-disable no-unused-vars */
// @flow
import React, { Component, type AbstractComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import numeral from 'numeral';
import { withTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import ItemConfirmation from 'components/ItemConfirmation';
import Input from 'components/Input';
import Button from 'components/Button';

import * as actionsBuy from 'store/Buy/actions';
import * as actionsBalance from 'store/Balance/actions';
import * as actionsAllow from 'store/Allow/actions';

import styles from './BuyConfirmation.module.scss';

// OwnProps refers to the props that were passed down by the parent.
type OwnProps = {||};

// Props created from OwnProps plus the props passed in by mapStateToProps and mapDispatchToProps
type Props = {|
  ...OwnProps,
  amount: string,
  balanceUsdt: string,
  referrerId: string,
  isVerifySuccess: boolean,
  isValidReferredId: boolean,
  isUsedReferral: boolean,
  isAllowBalance: boolean,
  isLoading: boolean,
  buyCloseModal: Function,
  setReferrerId: Function,
  verifyBuy: Function,
  buyProcess: Function,
  allowShowModal: Function,
  t: Function,
|};

export class BuyConfirmationComponent extends Component<Props> {
  goBack = () => {
    const { buyCloseModal, setReferrerId } = this.props;
    buyCloseModal();
    setReferrerId('');
  }

  buyNow = () => {
    const {
      verifyBuy,
      isVerifySuccess,
      buyProcess,
      referrerId,
      isUsedReferral,
      allowShowModal,
      isAllowBalance,
    } = this.props;
    if (!isAllowBalance
      && (isUsedReferral || isVerifySuccess || (referrerId && referrerId.length > 0))
    ) {
      allowShowModal();
    } else if (isAllowBalance) {
      buyProcess();
    } else {
      verifyBuy();
    }
  }

  render() {
    const {
      referrerId,
      isVerifySuccess,
      setReferrerId,
      amount,
      isValidReferredId,
      isUsedReferral,
      isLoading,
      balanceUsdt,
      t,
    } = this.props;
    return (
      <div className={styles.buyConfirmation}>
        <div className={styles.containerBody}>
          <div className={styles.title}>{t('Buying.Confirmation')}</div>

          <div className={styles.containerItems}>
            <ItemConfirmation
              title={t('Buying.Amount')}
              value={numeral(amount || 0).format('0,0.0000')}
            />

            <div className={styles.line} />

            <ItemConfirmation
              title={t('Total.Price')}
              value={numeral(amount || 0).format('0,0.0000')}
              isNotEstt
            />
          </div>

          {!isUsedReferral && !isVerifySuccess && (
            <div className={styles.containerInput}>
              <Input
                label={t('Referrer')}
                placeholder={`${t('E.g.')}: ABC123`}
                value={referrerId}
                onChange={({ target: { value } }) => setReferrerId(value)}
              />

              <span className={styles.message}>{t('Direct.Bonus')}</span>
            </div>
          )}

          <div className={styles.containerButtons}>
            <Button color="second" onClick={this.goBack}>
              {t('Go.Back')}
            </Button>
            <div className={styles.empty} />
            <Button
              color="primary"
              onClick={this.buyNow}
              disabled={
                (referrerId !== '' && !isValidReferredId)
                || !(parseFloat(amount) > 0)
                || parseFloat(amount) > parseFloat(balanceUsdt)
              }
              isLoading={isLoading}
              styleDefault={isMobile ? { opacity: 1 } : {}}
            >
              {t('Buy.Now')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isVerifySuccess: state.buy.isVerifySuccess,
  isLoading: state.buy.isLoading,
  amount: state.balance.amount,
  referrerId: state.balance.referrerId,
  isValidReferredId: state.balance.isValidReferredId,
  isUsedReferral: state.connect.isUsedReferral,
  isAllowBalance: state.allow.isAllowBalance,
  balanceUsdt: state.balance.balanceUsdt,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    buyCloseModal: actionsBuy.buyCloseModal,
    verifyBuy: actionsBuy.verifyBuy,
    buyProcess: actionsBuy.buyProcess,
    setReferrerId: actionsBalance.setReferrerId,
    allowShowModal: actionsAllow.allowShowModal,
  },
  dispatch,
);

export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('buyConfirmation')(BuyConfirmationComponent)): AbstractComponent<OwnProps>);
