// @flow

import { put, takeLatest, call } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
// import type { BalanceActions } from './types';
import abi from 'constants/abi.json';
import abiUsdt from 'constants/abiUsdt.json';
import { getWeb3, isMetaMaskInstalled } from 'utils/metamask';
import constants from '../../constants';
import { BALANCE_REQUEST } from './constants';

import * as actions from './actions';
import * as actionsConnect from '../Connect/actions';

function* balanceSaga(): Saga<void> {
  try {
    // place your code here
    // eslint-disable-next-line no-console
    console.log('balanceSaga', isMetaMaskInstalled());

    const web3 = yield call(getWeb3);

    const contract = new web3.eth.Contract(abi, constants.addressToken);

    const accounts = yield call(web3.eth.getAccounts);

    const myReferrals = yield call(contract.methods.getMyReferrals().call, { from: accounts[0] });

    const balance = yield call(contract.methods.balanceOf(accounts[0]).call);

    const balanceByTime = yield call(
      contract.methods.balanceByTime(accounts[0], parseInt((Date.now() / 1000), 0) + 86400).call,
    );

    const contractUsdt = new web3.eth.Contract(abiUsdt, constants.usdtAddress);

    const balanceUsdt = yield call(contractUsdt.methods.balanceOf(accounts[0]).call);

    const totalSupply = yield call(contract.methods.totalSupply().call);

    const exchangeAddress = yield call(contract.methods.exchangeAddress().call);

    const balanceExchange = yield call(contract.methods.balanceOf(exchangeAddress).call);

    const dailyInterest = new web3.utils.BN(balanceByTime).sub(new web3.utils.BN(balance));

    const parentReferral = yield call(contract.methods.parentReferral(accounts[0]).call);

    const holdersCounter = yield call(contract.methods.holdersCounter().call);

    yield put(actionsConnect.connectWalletSuccess({
      address: accounts[0],
      exchangeAddress,
      myReferrals,
      isUsedReferral: parentReferral !== '0x0000000000000000000000000000000000000000',
      holdersCounter,
    }));

    const sold = web3.utils.toWei(new web3.utils.BN(constants.balanceDefaultExchange), 'ether')
      .sub(new web3.utils.BN(balanceExchange));

    const total = new web3.utils.BN(totalSupply)
      .sub(web3.utils.toWei(new web3.utils.BN(constants.balanceReserve), 'ether'))
      .sub(sold);

    yield put(actions.getBalanceSuccess({
      balance: web3.utils.fromWei(balance, 'ether'),
      balanceUsdt: web3.utils.fromWei(balanceUsdt, 'ether'),
      totalSupply: web3.utils.fromWei(total, 'ether'),
      balanceExchange: web3.utils.fromWei(balanceExchange, 'ether'),
      sold: web3.utils.fromWei(String(sold), 'ether'),
      dailyInterest: parseFloat(web3.utils.fromWei(String(dailyInterest), 'ether')),
    }));
  } catch (err) {
    // and here
    // eslint-disable-next-line no-console
    console.log('error:', err);
    yield put(actions.getBalanceFail());
    yield put(actionsConnect.connectWalletFail());
  }
}

export default function* watchBalance(): Saga<void> {
  yield takeLatest(BALANCE_REQUEST, balanceSaga);
}
