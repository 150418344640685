// @flow

import {
  takeLatest, call, select, put, delay,
} from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { getWeb3, isMetaMaskLogin } from 'utils/metamask';
import abi from 'constants/abi.json';
import abiUsdt from 'constants/abiUsdt.json';
import { isMobile } from 'react-device-detect';

import constants from '../../constants';
import { ALLOW_REQUEST } from './constants';

import * as actionsAllow from './actions';
import * as actionsWarnning from '../Warning/actions';

function* allowSaga(): Saga<void> {
  try {
    // place your code here
    const amount = yield select((state) => state.balance.amount);

    const isSell = yield select((state) => state.sell.isShowModal);

    const web3 = yield call(getWeb3);

    const contract = new web3.eth.Contract(abi, constants.addressToken);

    const accounts = yield call(web3.eth.getAccounts);
    const myAddress = accounts[0];

    const exchangeAddress = yield call(contract.methods.exchangeAddress().call);

    // console.log('accounts', accounts);
    const contractUsdt = new web3.eth.Contract(abiUsdt, constants.usdtAddress);
    // console.log('constants.usdtAddress', constants.usdtAddress);

    const allowApprove = yield call(contractUsdt.methods.allowance(
      myAddress,
      exchangeAddress,
    ).call);


    if (isSell) {
      if (isMobile && isMetaMaskLogin()) {
        contract.methods.increaseAllowance(
          exchangeAddress,
          web3.utils.toWei(amount, 'ether').toString(),
        ).send({
          from: myAddress,
        });
        yield delay(25000);
      } else {
        yield call(contract.methods.increaseAllowance(
          exchangeAddress,
          web3.utils.toWei(amount, 'ether').toString(),
        ).send, {
          from: myAddress,
        });
      }
    } else if (isMobile && isMetaMaskLogin()) {
      if (allowApprove > 0) {
        contractUsdt.methods.approve(
          exchangeAddress,
          String(0),
        ).send({
          from: myAddress,
        });
      }
      yield delay(25000);
      contractUsdt.methods.approve(
        exchangeAddress,
        web3.utils.toWei(
          new web3.utils.BN(web3.utils.fromWei(allowApprove, 'ether'))
            .add(new web3.utils.BN(amount)),
          'ether',
        ).toString(),
      ).send({
        from: myAddress,
      });
      yield delay(25000);
    } else {
      if (allowApprove > 0) {
        yield call(contractUsdt.methods.approve(
          exchangeAddress,
          String(0),
        ).send, {
          from: myAddress,
        });
      }

      yield call(contractUsdt.methods.approve(
        exchangeAddress,
        web3.utils.toWei(
          new web3.utils.BN(web3.utils.fromWei(allowApprove, 'ether'))
            .add(new web3.utils.BN(amount)),
          'ether',
        ).toString(),
      ).send, {
        from: myAddress,
      });
    }

    yield put(actionsAllow.allowSuccess());
  } catch (err) {
    // and here
    // eslint-disable-next-line no-console
    console.log('err', err);
    yield put(actionsAllow.allowFail());
    yield put(actionsWarnning.setWarning('Transaction has been reverted. Try again later'));
  }
}

export default function* watchAllow(): Saga<void> {
  yield takeLatest(ALLOW_REQUEST, allowSaga);
}
