// @flow
import * as connectTypes from './constants';
import type { ConnectActions } from './types';

type State = {
  isConnected: boolean,
  isShowModal: boolean,
  isShowModalDownloadMetamask: boolean,
  address: string,
  isUsedReferral: boolean,
  myReferrals: Array<string>,
  holdersCounter: number,
};

const initialState: State = {
  isConnected: false,
  isShowModal: false,
  isShowModalDownloadMetamask: false,
  address: '',
  myReferrals: [],
  isUsedReferral: false,
  holdersCounter: 0,
};

export default (state: State = initialState, action: ConnectActions): State => {
  switch (action.type) {
    // place case statements here

    case connectTypes.CONNECT_REQUEST:
      return {
        ...state,
        isShowModal: false,
      };

    case connectTypes.CONNECT_SUCCESS:
      return {
        ...state,
        isConnected: true,
        address: action.payload.address,
        exchangeAddress: action.payload.exchangeAddress,
        myReferrals: action.payload.myReferrals,
        isUsedReferral: action.payload.isUsedReferral,
        holdersCounter: action.payload.holdersCounter,
      };

    case connectTypes.CONNECT_FAIL:
      return {
        ...state,
        isConnected: false,
        address: '',
      };

    case connectTypes.CLOSE_MODAL_CONNECT:
      return {
        ...state,
        isShowModal: false,
        isShowModalDownloadMetamask: false,
      };

    case connectTypes.SHOW_MODAL_CONNECT:
      return {
        ...state,
        isShowModal: true,
      };

    case connectTypes.SHOW_MODAL_DOWNLOAD_METAMASK:
      return {
        ...state,
        isShowModalDownloadMetamask: true,
      };

    default:
      return state;
  }
};
