/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from 'utils/history';

import warning from './Warning/reducers';
import connect from './Connect/reducers';
import balance from './Balance/reducers';
import buy from './Buy/reducers';
import sell from './Sell/reducers';
import allow from './Allow/reducers';

export default () => combineReducers({
  router: connectRouter(history),
  // place your reducers here
  warning,
  connect,
  balance,
  buy,
  sell,
  allow,
});
