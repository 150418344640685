// @flow
import React, { Component, type AbstractComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMetaMaskInstalled, isImTokenInstalled, isTrustInstalled } from 'utils/metamask';

import Header from 'components/Header';
import Footer from 'components/Footer';

import WarningModal from 'containers/WarningModal';
import ConnectModal from 'containers/ConnectModal';
import DownloadMetamaskModal from 'containers/DownloadMetamaskModal';
import BalanceTotal from 'containers/BalanceTotal';
import ListBalance from 'containers/ListBalance';
import BuyConfirmation from 'containers/BuyConfirmation';
import SellConfirmation from 'containers/SellConfirmation';
import BuySell from 'containers/BuySell';
import AllowBalance from 'containers/AllowBalance';
import WithoutReferrerConfirmation from 'containers/WithoutReferrerConfirmation';
import InvitationLink from 'containers/InvitationLink';

import * as actions from 'store/Connect/actions';

import styles from './Home.module.scss';

// OwnProps refers to the props that were passed down by the parent.
type OwnProps = {||};

// Props created from OwnProps plus the props passed in by mapStateToProps and mapDispatchToProps
type Props = {|
  ...OwnProps,
  isConnected: boolean,
  isActiveBuy: boolean,
  isVerify: boolean,
  isActiveSell: boolean,
  isVerifySell: boolean,
  isActiveAllow: boolean,
  showModalConnect: Function,
  showModalDownloadMetaMask: Function,
  connectWallet: Function,
|};

export class Home extends Component<Props> {
  connectWallet = () => {
    const {
      showModalConnect,
      showModalDownloadMetaMask,
      connectWallet,
    } = this.props;

    if (isMetaMaskInstalled()) {
      showModalConnect();
    } else if (isImTokenInstalled()) {
      connectWallet();
    } else if (isTrustInstalled()) {
      connectWallet();
    } else {
      showModalDownloadMetaMask();
    }
  }

  render() {
    const {
      isConnected,
      isActiveBuy,
      isVerify,
      isActiveSell,
      isVerifySell,
      isActiveAllow,
    } = this.props;

    return (
      <div className={styles.containerHome}>
        <div className={styles.containerTop}>

          {(isActiveBuy || isActiveSell) && (
            <div className={styles.overlay} />
          )}

          <Header
            connectWallet={this.connectWallet}
            isConnected={isConnected}
          />

          <div className={styles.balance}>
            <BalanceTotal />
          </div>

        </div>
        <div className={styles.containerBody}>
          <div className={styles.widget}>
            <BuySell />
          </div>
          <div className={styles.body}>
            {!isActiveBuy && !isActiveSell && (
              <div className={styles.containerForBalanceInvitaion}>
                <ListBalance />
                <InvitationLink />
              </div>
            )}

            {isActiveBuy && !isVerify && !isActiveAllow && (
              <BuyConfirmation />
            )}

            {isActiveSell && !isVerifySell && !isActiveAllow && (
              <SellConfirmation />
            )}

            {(isVerify || isVerifySell) && (
              <WithoutReferrerConfirmation />
            )}

            {(isActiveAllow) && (
              <AllowBalance />
            )}
          </div>

        </div>
        <div className={styles.containerFooter}>
          <Footer />
        </div>

        <WarningModal />
        <ConnectModal />
        <DownloadMetamaskModal />

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isConnected: state.connect.isConnected,
  isActiveBuy: state.buy.isShowModal,
  isVerify: state.buy.isVerify,
  isActiveSell: state.sell.isShowModal,
  isVerifySell: state.sell.isVerify,
  isActiveAllow: state.allow.isShowModal,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    showModalConnect: actions.showModalConnect,
    showModalDownloadMetaMask: actions.showModalDownloadMetaMask,
    connectWallet: actions.connectWallet,
  },
  dispatch,
);

export default (connect(mapStateToProps, mapDispatchToProps)(Home): AbstractComponent<OwnProps>);
