// @flow
import {
  takeLatest, call, select, put,
} from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { getWeb3 } from 'utils/metamask';
import abi from 'constants/abi.json';
import abiExchange from 'constants/abiExchange.json';
import { isMobile } from 'react-device-detect';

import constants from '../../constants';

import * as actionsSell from './actions';
import * as actionsBalance from '../Balance/actions';
import * as actionsAllow from '../Allow/actions';
import * as actionsWarnning from '../Warning/actions';

function* sellSaga(): Saga<void> {
  try {
    const amount = yield select((state) => state.balance.amount);
    const referrerId = yield select((state) => state.balance.referrerId);

    const web3 = yield call(getWeb3);

    const contract = new web3.eth.Contract(abi, constants.addressToken);
    const accounts = yield call(web3.eth.getAccounts);
    const myAddress = accounts[0];
    const exchangeAddress = yield call(contract.methods.exchangeAddress().call);

    // yield call(contract.methods.increaseAllowance(
    //   exchangeAddress,
    //   web3.utils.toWei(parseFloat(amount), 'ether').toString(),
    // ).send, {
    //   from: myAddress,
    // });

    const contractExchange = new web3.eth.Contract(abiExchange, exchangeAddress);

    if (isMobile) {
      contractExchange.methods.trade(
        constants.addressToken,
        web3.utils.toWei(amount, 'ether').toString(),
        constants.usdtAddress,
        web3.utils.toWei(amount, 'ether').toString(),
        referrerId === '' ? '0x0000000000000000000000000000000000000000' : referrerId,
      ).send({
        from: myAddress,
      });
    } else {
      yield call(contractExchange.methods.trade(
        constants.addressToken,
        web3.utils.toWei(amount, 'ether').toString(),
        constants.usdtAddress,
        web3.utils.toWei(amount, 'ether').toString(),
        referrerId === '' ? '0x0000000000000000000000000000000000000000' : referrerId,
      ).send, {
        from: myAddress,
      });
    }

    yield put(actionsBalance.setReferrerId(''));

    yield put(actionsSell.sellProcessSuccess());
    yield put(actionsAllow.allowClean());

    yield put(actionsBalance.getBalance());
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('error:', err);
    yield put(actionsAllow.allowFail());
    yield put(actionsSell.sellProcessFail());
    yield put(actionsWarnning.setWarning('Transaction has been reverted. Try again later'));
  }
}

export default function* watchSell(): Saga<void> {
  yield takeLatest('SELL_REQUEST', sellSaga);
}
