// @flow
import React, { Component, type AbstractComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import Modal from 'components/Modal';

import * as actionsWarnning from 'store/Warning/actions';
import styles from './WarningModal.module.scss';

// OwnProps refers to the props that were passed down by the parent.
type OwnProps = {||};

// Props created from OwnProps plus the props passed in by mapStateToProps and mapDispatchToProps
type Props = {|
  ...OwnProps,
  isShowModal: boolean,
  error: string,
  closeModalWarning: Function,
  t: Function,
|};

export class WarningModalComponent extends Component<Props> {
  render() {
    const {
      isShowModal, closeModalWarning, error, t,
    } = this.props;
    return (
      <Modal isOpen={isShowModal} onClose={() => closeModalWarning()}>
        <div className={styles.warningModal}>
          <div className={styles.containerTitle}>
            <div className={styles.title}>
              Something went wrong.
            </div>
            <div className={styles.title}>
              {error}
            </div>
          </div>
          <div className={styles.containerButton}>
            <button type="button" className={styles.button} onClick={() => closeModalWarning()}>{t('Go.back')}</button>
          </div>

        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  isShowModal: state.warning.isShowModal,
  error: state.warning.error,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    closeModalWarning: actionsWarnning.closeModalWarning,
  },
  dispatch,
);

export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('warningModal')(WarningModalComponent)): AbstractComponent<OwnProps>);
