// @flow
import React, { Component, type AbstractComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import Input from 'components/Input';
import Button from 'components/Button';

import * as actionsBuy from 'store/Buy/actions';
import * as actionsSell from 'store/Sell/actions';
import * as actionsBalance from 'store/Balance/actions';

import styles from './WithoutReferrerConfirmation.module.scss';

// OwnProps refers to the props that were passed down by the parent.
type OwnProps = {||};

// Props created from OwnProps plus the props passed in by mapStateToProps and mapDispatchToProps
type Props = {|
  ...OwnProps,
  isActiveSell: boolean,
  isValidReferredId: boolean,
  referrerId: string,
    buyCloseModal: Function,
      sellCloseModal: Function,
        verifyBuySuccess: Function,
          verifySellSuccess: Function,
          setReferrerId: Function,
          t: Function,
|};

export class WithoutReferrerConfirmationComponent extends Component<Props> {
  render() {
    const {
      buyCloseModal,
      verifyBuySuccess,
      isActiveSell,
      verifySellSuccess,
      sellCloseModal,
      referrerId,
      setReferrerId,
      isValidReferredId,
      t,
    } = this.props;

    return (
      <div className={styles.withoutReferrerConfirmation}>
        <div className={styles.containerBody}>
          <div className={styles.title}>
            {t('Are.you.sure')}
          </div>

          <div className={styles.containerInput}>
            <Input
              label={t('Referrer')}
              placeholder={`${t('E.g.')}: ABC123`}
              value={referrerId}
              onChange={({ target: { value } }) => setReferrerId(value)}
            />

            <span className={styles.message}>
              {t('Your.referrer')}
            </span>
          </div>

          <div className={styles.containerButtons}>
            <Button
              color="second"
              onClick={() => (!isActiveSell ? buyCloseModal() : sellCloseModal())}
            >
              {t('Go.Back')}
            </Button>
            <div className={styles.empty} />
            <Button
              color="red"
              disabled={referrerId !== '' && !isValidReferredId}
              onClick={() => (isActiveSell ? verifySellSuccess() : verifyBuySuccess())}
            >
              {t('Proceed')}
            </Button>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isActiveSell: state.sell.isShowModal,
  referrerId: state.balance.referrerId,
  isValidReferredId: state.balance.isValidReferredId,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    buyCloseModal: actionsBuy.buyCloseModal,
    sellCloseModal: actionsSell.sellCloseModal,
    verifyBuySuccess: actionsBuy.verifyBuySuccess,
    verifySellSuccess: actionsSell.verifySellSuccess,
    setReferrerId: actionsBalance.setReferrerId,
  },
  dispatch,
);

export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('withoutReferrerConfirmation')(WithoutReferrerConfirmationComponent)): AbstractComponent<OwnProps>);
