// @flow
import React, { Component, type AbstractComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import numeral from 'numeral';
import { withTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import ItemConfirmation from 'components/ItemConfirmation';
import Button from 'components/Button';

import * as actionsSell from 'store/Sell/actions';
import * as actionsAllow from 'store/Allow/actions';
// import * as actionsBalance from 'store/Balance/actions';

import styles from './SellConfirmation.module.scss';

// OwnProps refers to the props that were passed down by the parent.
type OwnProps = {||};

// Props created from OwnProps plus the props passed in by mapStateToProps and mapDispatchToProps
type Props = {|
  ...OwnProps,
  amount: string,
  balance: string,
  isAllowBalance: boolean,
  isLoading: boolean,
  sellCloseModal: Function,
  sellProcess: Function,
  allowShowModal: Function,
  t: Function,
|};

export class SellConfirmationComponent extends Component<Props> {
  goBack = () => {
    const { sellCloseModal } = this.props;
    sellCloseModal();
  }

  handleSell = () => {
    const {
      sellProcess,
      isAllowBalance,
      allowShowModal,
    } = this.props;

    if (!isAllowBalance) {
      allowShowModal();
    } else {
      sellProcess();
    }
  }

  render() {
    const {
      amount,
      balance,
      isLoading,
      t,
    } = this.props;

    const fee = amount ? parseFloat(amount) * 0.008 : 0;
    const total = amount ? parseFloat(amount) - (parseFloat(amount) * 0.008) : 0;

    return (
      <div className={styles.sellConfirmation}>
        <div className={styles.containerBody}>
          <div className={styles.title}>
            {t('Selling.Confirmation')}
          </div>

          <div className={styles.containerItems}>
            <ItemConfirmation
              title={t('Selling.Amount')}
              value={numeral(amount || 0).format('0,0.0000')}
            />

            <div className={styles.line} />

            <ItemConfirmation
              title={t('Receiving.Amount')}
              value={numeral(amount || 0).format('0,0.0000')}
              isNotEstt
            />

            <div className={styles.line} />

            <ItemConfirmation
              title={t('Exchange.Fee')}
              value={`- ${numeral(fee || 0).format('0,0.0000')}`}
              isNotEstt
            />

            <div className={styles.line} />

            <ItemConfirmation
              title={t('Total.To.Receive')}
              value={`${numeral(total || 0).format('0,0.0000')}`}
              isNotEstt
            />
          </div>

          <div className={styles.containerButtons}>
            <Button color="second" onClick={this.goBack}>
              {t('Go.Back')}
            </Button>
            <div className={styles.empty} />
            <Button
              color="red"
              onClick={this.handleSell}
              disabled={(parseFloat(amount) > parseFloat(balance)) || !(parseFloat(amount) > 0)}
              isLoading={isLoading}
              styleDefault={isMobile ? { opacity: 1 } : {}}
            >
              {t('Sell.Now')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  amount: state.balance.amount,
  balance: state.balance.balance,
  isAllowBalance: state.allow.isAllowBalance,
  isLoading: state.sell.isLoading,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    sellCloseModal: actionsSell.sellCloseModal,
    sellProcess: actionsSell.sellProcess,
    allowShowModal: actionsAllow.allowShowModal,
  },
  dispatch,
);

export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('sellConfirmation')(SellConfirmationComponent)): AbstractComponent<OwnProps>);
