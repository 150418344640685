// @flow
import * as warningTypes from './constants';
import type { WarningActions } from './types';

type State = {
  error: string,
  isShowModal: boolean,
};

const initialState: State = {
  error: '',
  isShowModal: false,
};

export default (state: State = initialState, action: WarningActions): State => {
  switch (action.type) {
    // place case statements here
    case warningTypes.SET_WARNING:
      return {
        ...state,
        error: action.payload,
        isShowModal: true,
      };

    case warningTypes.CLOSE_MODAL_WARNING:
      return {
        ...state,
        isShowModal: false,
      };

    default:
      return state;
  }
};
