export default {
  en: {
    'Connect.a.wallet.to.start': 'Connect a wallet to start',
    'By.connecting': 'By connecting, I accept EST Token’s Terms of Service',
  },
  ch: {
    'Connect.a.wallet.to.start': '连接钱包开始',
    'By.connecting': '通过连接表示我已同意ESTT条款',
  },
};
