// @flow
import React from 'react';
import RModal from 'react-modal';

import styles from './Modal.module.scss';

type Props = {
  children: React$Node,
  onClose: () => void,
  isOpen: boolean,
};

const Modal = ({ children, onClose, isOpen }: Props) => (
  <RModal
    shouldCloseOnEsc
    shouldCloseOnOverlayClick
    isOpen={isOpen}
    className={styles.modal}
    overlayClassName={styles.overlay}
    onRequestClose={onClose}
  >
    {children}
  </RModal>
);

export default Modal;
