export const BUY_REQUEST = 'BUY_REQUEST';
export const BUY_SUCCESS = 'BUY_SUCCESS';
export const BUY_FAIL = 'BUY_FAIL';

export const BUY_SHOW_MODAL = 'BUY_SHOW_MODAL';
export const BUY_CLOSE_MODAL = 'BUY_CLOSE_MODAL';

export const BUY_VERIFY = 'BUY_VERIFY';
export const BUY_VERIFY_SUCCESS = 'BUY_VERIFY_SUCCESS';
export const BUY_VERIFY_FAIL = 'BUY_VERIFY_FAIL';
