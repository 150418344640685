// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import contracts from '../../constants';
import styles from './Footer.module.scss';

type Props = {
  t: Function,
};

const Footer = ({ t }: Props) => (
  <div className={styles.containetFooter}>
    © 2022 estt.io
    <a href={`https://bscscan.com/token/${contracts.addressToken}`} className={styles.contactLink} target="_blank" rel="noopener noreferrer">
      {t('Contract.Info')}
    </a>
  </div>
);

export default withTranslation('footer')(Footer);
