// @flow
import React, { Component, type AbstractComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import Modal from 'components/Modal';

import iconConnectImg from 'assets/images/iconConnect.png';
import metamaskImg from 'assets/images/metamask.png';
import arrowForwardImg from 'assets/images/arrowForward.png';

import * as actionsConnect from 'store/Connect/actions';

import styles from './ConnectModal.module.scss';

// OwnProps refers to the props that were passed down by the parent.
type OwnProps = {||};

// Props created from OwnProps plus the props passed in by mapStateToProps and mapDispatchToProps
type Props = {|
  ...OwnProps,
  isShowModal: boolean,
  closeModalConnect: Function,
  connectWallet: Function,
  t: Function,
|};

export class ConnectModalComponent extends Component<Props> {
  render() {
    const {
      isShowModal, closeModalConnect, connectWallet, t,
    } = this.props;
    return (
      <Modal isOpen={isShowModal} onClose={() => closeModalConnect()}>

        <div className={styles.connectModal}>
          <div className={styles.containerTitle}>
            <img src={iconConnectImg} alt="iconConnectImg" className={styles.img} />
            <div className={styles.title}>
              {t('Connect.a.wallet.to.start')}
            </div>
          </div>
          <div className={styles.containerBody}>

            <button className={styles.item} type="button" onClick={() => connectWallet()}>
              <div className={styles.containerTitle}>
                <img src={metamaskImg} alt="metamask" />
                <div className={styles.title}>
                  Metamask
                </div>
              </div>
              <img src={arrowForwardImg} alt="arrowForward" />
            </button>
          </div>
          <div className={styles.containerFooter}>
            {t('By.connecting')}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  isShowModal: state.connect.isShowModal,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    closeModalConnect: actionsConnect.closeModalConnect,
    connectWallet: actionsConnect.connectWallet,
  },
  dispatch,
);

export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('connectModal')(ConnectModalComponent)): AbstractComponent<OwnProps>);
