import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import allowBalance from 'containers/AllowBalance/i18n';
import balanceTotal from 'containers/BalanceTotal/i18n';
import header from 'components/Header/i18n';
import input from 'components/Input/i18n';
import button from 'components/Button/i18n';
import buySell from 'components/BuySellWidget/i18n';
import buyConfirmation from 'containers/BuyConfirmation/i18n';
import connectModal from 'containers/ConnectModal/i18n';
import invitationLink from 'containers/InvitationLink/i18n';
import listBalance from 'containers/ListBalance/i18n';
import sellConfirmation from 'containers/SellConfirmation/i18n';
import warningModal from 'containers/WarningModal/i18n';
import withoutReferrerConfirmation from 'containers/WithoutReferrerConfirmation/i18n';
import menu from 'pages/Menu/i18n';
import footer from 'components/Footer/i18n';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    allowBalance: allowBalance.en,
    balanceTotal: balanceTotal.en,
    header: header.en,
    buySell: buySell.en,
    buyConfirmation: buyConfirmation.en,
    connectModal: connectModal.en,
    invitationLink: invitationLink.en,
    input: input.en,
    listBalance: listBalance.en,
    sellConfirmation: sellConfirmation.en,
    warningModal: warningModal.en,
    withoutReferrerConfirmation: withoutReferrerConfirmation.en,
    menu: menu.en,
    footer: footer.en,
    button: button.en,
  },
  ch: {
    allowBalance: allowBalance.ch,
    balanceTotal: balanceTotal.ch,
    header: header.ch,
    buySell: buySell.ch,
    buyConfirmation: buyConfirmation.ch,
    connectModal: connectModal.ch,
    invitationLink: invitationLink.ch,
    input: input.ch,
    listBalance: listBalance.ch,
    sellConfirmation: sellConfirmation.ch,
    warningModal: warningModal.ch,
    withoutReferrerConfirmation: withoutReferrerConfirmation.ch,
    menu: menu.ch,
    footer: footer.ch,
    button: button.ch,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
