import Web3 from 'web3';

export const isMetaMaskInstalled = () => {
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isMetaMask);
};

export const isImTokenInstalled = () => {
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isImToken);
};

export const isTrustInstalled = () => {
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isTrust);
};

export const isMetaMaskLogin = () => {
  const { ethereum, web3 } = window;
  return Boolean(ethereum && ethereum.isMetaMask) && Boolean(web3);
};

export const isImTokenLogin = () => {
  const { ethereum, web3 } = window;
  return Boolean(ethereum && ethereum.isImToken) && Boolean(web3);
};

export const isTrustLogin = () => {
  const { ethereum, web3 } = window;
  return Boolean(ethereum && ethereum.isTrust) && Boolean(web3);
};

export const getWeb3 = async () => {
  if (window.ethereum) {
    const web3 = new Web3(window.ethereum);
    // eslint-disable-next-line no-useless-catch
    try {
      // await window.ethereum.enable();
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      return web3;
    } catch (error) {
      throw error;
    }
  } else if (window.web3) {
    const { web3 } = window;
    return web3;
  } else {
    const provider = new Web3.providers.HttpProvider('http://localhost:8545');
    const web3 = new Web3(provider);
    return web3;
  }
};
