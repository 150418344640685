// @flow
import React from 'react';
import {
  estt,
  tetherImg,
} from '../../assets/images';

import styles from './ItemConfirmation.module.scss';

type Props = {
  title: string,
  value: string,
  isNotEstt?: boolean
}
const ItemConfirmation = ({ title, value, isNotEstt }: Props) => (
  <div className={styles.itemConfirmation}>
    <div className={styles.itemTitle}>
      {title}
    </div>
    <div className={styles.itemValue}>
      {value}

      <div className={styles.itemSymbol}>
        {!isNotEstt && (
          <img src={estt} alt="estt" className={styles.estt} />
        )}
        {isNotEstt && (
          <img src={tetherImg} alt="tether" className={styles.tether} />
        )}
      </div>
    </div>
  </div>
);

ItemConfirmation.defaultProps = {
  isNotEstt: false,
};

export default ItemConfirmation;
