// @flow

import type { BalanceActions, BalanceSuccess } from './types';
import * as balanceTypes from './constants';

export const getBalance = (): BalanceActions => ({
  type: balanceTypes.BALANCE_REQUEST,
  payload: null,
});

export const getBalanceSuccess = (payload: BalanceSuccess): BalanceActions => ({
  type: balanceTypes.BALANCE_SUCCESS,
  payload,
});

export const getBalanceFail = (): BalanceActions => ({
  type: balanceTypes.BALANCE_FAIL,
  payload: null,
});

export const setAmountForSellBuy = (value: string): BalanceActions => ({
  type: balanceTypes.SET_AMOUNT_FOR_SELL_BUY,
  payload: value,
});

export const setReferrerId = (value: string): BalanceActions => ({
  type: balanceTypes.SET_REFERRER_ID,
  payload: value,
});
