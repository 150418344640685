export default {
  en: {
    'Selling.Confirmation': 'Selling Confirmation',
    'Selling.Amount': 'Selling Amount',
    'Receiving.Amount': 'Receiving Amount',
    'Exchange.Fee': 'Exchange Fee (0.8%)',
    'Total.To.Receive': 'Total To Receive',
    'Go.Back': 'Go Back',
    'Sell.Now': 'Sell Now',
  },
  ch: {
    'Selling.Confirmation': '确认售出',
    'Selling.Amount': '售出数额',
    'Receiving.Amount': '收款数额',
    'Exchange.Fee': '兑换费 (0.8%)',
    'Total.To.Receive': '总接收额',
    'Go.Back': '返回',
    'Sell.Now': '售出',
  },
};
