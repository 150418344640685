// @flow
import * as allowTypes from './constants';
import type { AllowActions } from './types';

type State = {};

const initialState: State = {};

export default (state: State = initialState, action: AllowActions): State => {
  switch (action.type) {
    // place case statements here
    case allowTypes.ALLOW_SHOW_MODAL:
      return {
        ...state,
        isShowModal: true,
      };

    case allowTypes.ALLOW_CLOSE_MODAL:
      return {
        ...state,
        isShowModal: false,
      };

    case allowTypes.ALLOW_REQUEST:
      return {
        ...state,
        isLoading: true,
        isAllowBalance: false,
      };

    case allowTypes.ALLOW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAllowBalance: true,
        isShowModal: false,
      };

    case allowTypes.ALLOW_FAIL:
      return {
        ...state,
        isLoading: false,
        isAllowBalance: false,
      };

    default:
      return state;
  }
};
