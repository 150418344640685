// @flow

import type { WarningActions } from './types';
import * as warningTypes from './constants';

export const setWarning = (error: string): WarningActions => ({
  type: warningTypes.SET_WARNING,
  payload: error,
});

export const closeModalWarning = (): WarningActions => ({
  type: warningTypes.CLOSE_MODAL_WARNING,
  payload: null,
});
