// @flow
import React, { useState } from 'react';
import numeral from 'numeral';
import NumberFormat from 'react-number-format';
import { withTranslation } from 'react-i18next';
import constants from '../../constants';
import styles from './BuySellWidget.module.scss';

type Props = {
  isActiveBuy: boolean,
  isActiveSell: boolean,
  balance: string,
  amount: number,
  onChange: Function,
  onBuy: Function,
  onSell: Function,
  t: Function,
};
const BuySellWidget = ({
  onBuy,
  onSell,
  isActiveBuy,
  isActiveSell,
  balance,
  amount,
  onChange,
  t,
}: Props) => {
  const [value, setValue] = useState(amount);
  const minus = () => {
    setValue(
      parseFloat(value) > 0 ? parseFloat(value) - constants.deltaBuySell : 0,
    );
    onChange(
      parseFloat(value) > 0 ? parseFloat(value) - constants.deltaBuySell : 0,
    );
  };
  const plus = () => {
    setValue(parseFloat(value) + constants.deltaBuySell);
    onChange(parseFloat(value) + constants.deltaBuySell);
  };

  const getColorBottom = () => {
    if (isActiveBuy) return '#002469';
    if (isActiveSell) return '#EB5757';
    return '#002469';
  };

  const handleChange = ({ value: newAmount }) => {
    setValue(newAmount);
    onChange(newAmount);
  };


  return (
    <div className={styles.containerBuySellWidget}>
      <div className={styles.containerTop}>
        {!isActiveBuy && !isActiveSell && (
          <button
            className={styles.buttonLeft}
            type="button"
            onClick={minus}
            disabled={!(parseFloat(value) > 0)}
          >
            -
          </button>
        )}
        <NumberFormat
          displayType="input"
          // type="number"
          thousandSeparator
          // removeFormatting={(formattedValue) => { parseFloat(formattedValue); }}
          // customInput={TextField}
          prefix=""
          renderText={() => 1213}
          // inputType="number"
          precision="2"
          selectAllOnFocus
          className={styles.input}
          value={value}
          // isNumericString
          onValueChange={handleChange}
          // format={(val) => `${format(val)}` || '0'}
        />
        {!isActiveBuy && !isActiveSell && (
          <button
            className={styles.buttonRight}
            type="button"
            onClick={plus}
            disabled={!(parseFloat(value) >= 0)}
          >
            +
          </button>
        )}
      </div>
      <div
        className={styles.containerBottom}
        style={{ backgroundColor: getColorBottom() }}
      >
        {!isActiveBuy && !isActiveSell && (
          <>
            <button
              className={styles.buttonLeft}
              type="button"
              onClick={onSell}
            >
              {t('Sell')}
            </button>
            <button
              className={styles.buttonRight}
              type="button"
              onClick={onBuy}
            >
              {t('Buy')}
            </button>
          </>
        )}
        {(isActiveBuy || isActiveSell) && (
          <div className={styles.conntainerDescription}>
            <div className={styles.label}>
              {t('Available')}
              {isActiveBuy ? ' USDT' : ' ESTT'}
            </div>
            <div className={styles.value}>{numeral(balance).format('0,0.0000')}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation('buySell')(BuySellWidget);
