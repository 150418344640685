// @flow
import * as buyTypes from './constants';
import type { BuyActions } from './types';

type State = {
  isShowModal: boolean,
  isVerify: boolean,
};

const initialState: State = {
  isShowModal: false,
  isVerify: false,
};

export default (state: State = initialState, action: BuyActions): State => {
  switch (action.type) {
    // place case statements here

    case buyTypes.BUY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case buyTypes.BUY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isShowModal: false,
        isVerify: false,
      };

    case buyTypes.BUY_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case buyTypes.BUY_SHOW_MODAL:
      return {
        ...state,
        isShowModal: true,
      };

    case buyTypes.BUY_CLOSE_MODAL:
      return {
        ...state,
        isShowModal: false,
        isVerify: false,
        isVerifySuccess: false,
      };

    case buyTypes.BUY_VERIFY:
      return {
        ...state,
        isVerify: true,
      };

    case buyTypes.BUY_VERIFY_SUCCESS:
      return {
        ...state,
        isVerify: false,
        isVerifySuccess: true,
      };

    case buyTypes.BUY_VERIFY_FAIL:
      return {
        ...state,
        isVerify: false,
        isVerifySuccess: false,
      };

    default:
      return state;
  }
};
