export default {
  en: {
    Home: 'Home',
    'Token.Sale': 'Token Sale',
    Resources: 'Resources',
    'Contact.Us': 'Contact Us',
    'About.ESTT': 'About ESTT',
  },
  ch: {
    Home: '主页',
    'Token.Sale': '货币销售',
    Resources: '资源',
    'Contact.Us': '联系我们',
    'About.ESTT': '关于ESTT',
  },
};
