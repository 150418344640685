// @flow

import type { AllowActions } from './types';
import * as allowTypes from './constants';

export const allowRequest = (): AllowActions => ({
  type: allowTypes.ALLOW_REQUEST,
  payload: null,
});

export const allowSuccess = (): AllowActions => ({
  type: allowTypes.ALLOW_SUCCESS,
  payload: null,
});

export const allowFail = (): AllowActions => ({
  type: allowTypes.ALLOW_FAIL,
  payload: null,
});

export const allowClean = (): AllowActions => ({
  type: allowTypes.ALLOW_FAIL,
  payload: null,
});

export const allowShowModal = (): AllowActions => ({
  type: allowTypes.ALLOW_SHOW_MODAL,
  payload: null,
});

export const allowCloseModal = (): AllowActions => ({
  type: allowTypes.ALLOW_CLOSE_MODAL,
  payload: null,
});
