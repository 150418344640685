export const SELL_REQUEST = 'SELL_REQUEST';
export const SELL_SUCCESS = 'SELL_SUCCESS';
export const SELL_FAIL = 'SELL_FAIL';

export const SELL_SHOW_MODAL = 'SELL_SHOW_MODAL';
export const SELL_CLOSE_MODAL = 'SELL_CLOSE_MODAL';

export const SELL_VERIFY = 'SELL_VERIFY';
export const SELL_VERIFY_SUCCESS = 'SELL_VERIFY_SUCCESS';
export const SELL_VERIFY_FAIL = 'SELL_VERIFY_FAIL';
