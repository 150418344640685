// @flow
import React, { Component, type AbstractComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import numeral from 'numeral';
import { isMobile } from 'react-device-detect';
import ItemConfirmation from 'components/ItemConfirmation';
import Button from 'components/Button';

import * as actionsAllow from 'store/Allow/actions';

import styles from './AllowBalance.module.scss';

// OwnProps refers to the props that were passed down by the parent.
type OwnProps = {||};

// Props created from OwnProps plus the props passed in by mapStateToProps and mapDispatchToProps
type Props = {|
  ...OwnProps,
  amount: string,
  balanceUsdt: string,
  isLoading: boolean,
  balance: string,
  isSell: boolean,
  t: Function,
  allowCloseModal: Function,
  allowRequest: Function,
|};

export class AllowBalanceComponent extends Component<Props> {
  goBack = () => {
    const { allowCloseModal } = this.props;
    allowCloseModal();
  }

  allowNow = () => {
    const { allowRequest } = this.props;
    allowRequest();
  }

  render() {
    const {
      amount,
      isLoading,
      balanceUsdt,
      balance,
      isSell,
      t,
    } = this.props;
    return (
      <div className={styles.allowBalance}>
        <div className={styles.containerBody}>
          <div className={styles.title}>
            {t('Allow.balance')}
          </div>

          <div className={styles.containerItems}>

            <ItemConfirmation
              title={t('Allow.amount')}
              value={numeral(amount || 0).format('0,0.0000')}
              isNotEstt={!isSell}
            />

            <div className={styles.line} />

            <ItemConfirmation
              title={`${t('Balanсe')} ${isSell ? 'ESTT' : 'USDT'}`}
              value={isSell ? numeral(balance || 0).format('0,0.0000') : numeral(balanceUsdt || 0).format('0,0.0000')}
              isNotEstt={!isSell}
            />

          </div>

          <div className={styles.containerButtons}>
            <Button color="second" onClick={this.goBack}>
              {t('Go.Back')}
            </Button>
            <div className={styles.empty} />
            <Button
              color="primary"
              onClick={this.allowNow}
              isLoading={isLoading}
              styleDefault={isMobile ? { opacity: 1 } : {}}
            >
              {t('Allow.Now')}
            </Button>
          </div>
          <div className={styles.footer}>
            {t('Do.not.close')}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  amount: state.balance.amount,
  balanceUsdt: state.balance.balanceUsdt,
  balance: state.balance.balance,
  isLoading: state.allow.isLoading,
  isSell: state.sell.isShowModal,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    allowCloseModal: actionsAllow.allowCloseModal,
    allowRequest: actionsAllow.allowRequest,
  },
  dispatch,
);

export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('allowBalance')(AllowBalanceComponent)): AbstractComponent<OwnProps>);
