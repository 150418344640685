// @flow

import {
  takeLatest, call, select, put, delay,
} from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { getWeb3 } from 'utils/metamask';
import abi from 'constants/abi.json';
import abiExchange from 'constants/abiExchange.json';
// import abiUsdt from 'constants/abiUsdt.json';
import { isMobile } from 'react-device-detect';
import constants from '../../constants';

import * as actionsBuy from './actions';
import * as actionsWarnning from '../Warning/actions';
import * as actionsBalance from '../Balance/actions';
import * as actionsAllow from '../Allow/actions';

function* buySaga(): Saga<void> {
  try {
    // eslint-disable-next-line no-console
    console.log('buySaga');

    const amount = yield select((state) => state.balance.amount);
    const referrerId = yield select((state) => state.balance.referrerId);

    const web3 = yield call(getWeb3);

    const contract = new web3.eth.Contract(abi, constants.addressToken);
    const accounts = yield call(web3.eth.getAccounts);
    const myAddress = accounts[0];
    const exchangeAddress = yield call(contract.methods.exchangeAddress().call);

    const contractExchange = new web3.eth.Contract(abiExchange, exchangeAddress);

    if (isMobile) {
      contractExchange.methods.trade(
        constants.usdtAddress,
        web3.utils.toWei(amount, 'ether').toString(),
        constants.addressToken,
        web3.utils.toWei(amount, 'ether').toString(),
        referrerId === '' ? '0x0000000000000000000000000000000000000000' : referrerId,
      ).send({
        from: myAddress,
      });
      yield delay(10000);
    } else {
      yield call(contractExchange.methods.trade(
        constants.usdtAddress,
        web3.utils.toWei(amount, 'ether').toString(),
        constants.addressToken,
        web3.utils.toWei(amount, 'ether').toString(),
        referrerId === '' ? '0x0000000000000000000000000000000000000000' : referrerId,
      ).send, {
        from: myAddress,
      });
    }

    yield put(actionsBalance.setReferrerId(''));

    yield put(actionsBuy.buyProcessSuccess());
    yield put(actionsAllow.allowClean());

    yield put(actionsBalance.getBalance());
  } catch (err) {
    // and here
    // eslint-disable-next-line no-console
    console.log(' error:', err);
    // alert(err);
    yield put(actionsAllow.allowFail());
    yield put(actionsBuy.buyProcessFail());
    yield put(actionsWarnning.setWarning('Transaction has been reverted. Try again later'));
  }
}

export default function* watchBuy(): Saga<void> {
  yield takeLatest('BUY_REQUEST', buySaga);
}
