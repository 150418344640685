/* eslint-disable no-return-assign */
// @flow
import React, { Component, type AbstractComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import Input from 'components/Input';

import styles from './InvitationLink.module.scss';

// OwnProps refers to the props that were passed down by the parent.
type OwnProps = {||};

// Props created from OwnProps plus the props passed in by mapStateToProps and mapDispatchToProps
type Props = {|
  ...OwnProps,
  address: string,
  myReferrals: Array<string>,
  t: Function,
|};

export class InvitationLinkComponent extends Component<Props> {
  fooRef = null;

  handleCopy = () => {
    ReactTooltip.show(this.fooRef);
    setTimeout(() => {
      ReactTooltip.hide(this.fooRef);
    }, 3000);
  };

  render() {
    const { address, myReferrals, t } = this.props;
    return (
      <div className={styles.invitationLink}>
        <div className={styles.label}>{t('Invitation.Link')}</div>
        <div className={styles.containerInput}>
          <Input
            withCoppy
            withCountPeople
            isValueCenter
            value={address ? `${document.location.origin}/?referrer=${address}` : ''}
            onChange={() => {}}
            onClick={this.handleCopy}
            disabled
            countPeople={myReferrals.length}
          />
          <div className={styles.containerCopied}>
            <p
              ref={(ref) => (this.fooRef = ref)}
              data-tip={t('Copied')}
              className={styles.copied}
            />
          </div>
        </div>
        <ReactTooltip className={styles.tooltip} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  balance: state.balance.balance,
  dailyInterest: state.balance.dailyInterest,
  address: state.connect.address,
  myReferrals: state.connect.myReferrals,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch,
);
export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('invitationLink')(InvitationLinkComponent)): AbstractComponent<OwnProps>);
