export default {
  en: {
    'Buying.Confirmation': 'Buying Confirmation',
    'Buying.Amount': 'Buying Amount',
    'Total.Price': 'Total Price',
    Referrer: "Referrer's ID",
    'E.g.': 'E.g.',
    'Direct.Bonus': 'Your referrer will receive a Direct Bonus of 0.025%.',
    'Go.Back': 'Go Back',
    'Buy.Now': 'Buy Now',
  },
  ch: {
    'Go.Back': '返回',
    'Buying.Confirmation': '确认购入',
    'Buying.Amount': '购入数额',
    'Total.Price': '总价',
    Referrer: '推荐人ID',
    'E.g.': '例',
    'Direct.Bonus': '您的推荐人将获得0.025%的直接奖金',
    'Buy.Now': '购入',
  },
};
