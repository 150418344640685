// @flow
import React, { type ReactNode } from 'react';
import styles from './ItemAccount.module.scss';

type Props = {
  img: ReactNode,
  button?: ReactNode,
  title: string,
  value: string | ReactNode,
}
const ItemAccount = ({
  img, title, value, button,
}: Props) => (
  <div className={styles.itemAccount}>
    <div className={styles.containerImg}>
      <img src={img} alt={title} className={styles.img} />
    </div>
    <div className={styles.containerDesc}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.value}>
        {value}
      </div>
    </div>

    {button && (
      <div className={styles.containerAction}>
        {button}
      </div>
    )}
  </div>
);

ItemAccount.defaultProps = {
  button: null,
};

export default ItemAccount;
