export default {
  en: {
    Sell: 'Sell',
    Buy: 'Buy',
    Available: 'Available',
  },
  ch: {
    Sell: '售出',
    Buy: '购入',
    Available: '可用余额',
  },
};
