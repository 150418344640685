// @flow
import React, { Component, type AbstractComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isChrome, isBrowser } from 'react-device-detect';

import Modal from 'components/Modal';

import * as actionsConnect from 'store/Connect/actions';
import styles from './DownloadMetamaskModal.module.scss';

// OwnProps refers to the props that were passed down by the parent.
type OwnProps = {||};

// Props created from OwnProps plus the props passed in by mapStateToProps and mapDispatchToProps
type Props = {|
  ...OwnProps,
  isShowModal: boolean,
    closeModalConnect: Function,
|};

export class DownloadMetamaskModalComponent extends Component<Props> {
  render() {
    const { isShowModal, closeModalConnect } = this.props;
    return (
      <Modal isOpen={isShowModal} onClose={() => closeModalConnect()}>
        <div className={styles.warningModal}>
          {isChrome && isBrowser && (
            <>
              <div className={styles.containerTitle}>
                <div className={styles.title}>
                  Metamask is not installed.
                </div>
                <div className={styles.title}>
                  Please, install Metamask extension
                </div>

              </div>
              <div className={styles.containerButton}>
                <a
                  className={styles.button}
                  href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                >
                  Click here to download Metamask extension
                </a>
              </div>
            </>
          )}

          {!isChrome && (
            <div className={styles.containerTitle}>
              <div className={styles.title}>
                Please use desktop Google Chrome browser
              </div>
              <div className={styles.title}>
                or install mobile app for your device (Imtoken or Metamask).
              </div>
            </div>
          )}

        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  isShowModal: state.connect.isShowModalDownloadMetamask,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    closeModalConnect: actionsConnect.closeModalConnect,
  },
  dispatch,
);

export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(DownloadMetamaskModalComponent): AbstractComponent<OwnProps>);
