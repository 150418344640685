// @flow
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
// pages
import HomePage from 'pages/Home';
import MenuPage from 'pages/Menu';
import NotFoundPage from 'pages/NotFound';

import styles from './App.module.scss';

type Props = {};

class App extends Component<Props> {
  render() {
    return (
      <div className={styles.app}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/menu" component={MenuPage} />
          <Route path="/" component={NotFoundPage} />
        </Switch>
        <ReactTooltip className={styles.tooltip} id="example" />
      </div>
    );
  }
}

export default App;
