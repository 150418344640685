export default {
  en: {
    'My.ID': 'My ID',
    Copy: 'Copy',
    Copied: 'Copied',
    'My.Token.Balance': 'My Token Balance',
    'Pending.Interest': 'Pending Interest',
    'Available.Balance': 'Available Balance',
    'Active.Referral': 'Active Referral',
  },
  ch: {
    'My.ID': '我的ID',
    'Invitation.Link': '邀请链接',
    Copy: '拷贝',
    Copied: '已拷贝',
    'My.Token.Balance': '我的货币余额',
    'Pending.Interest': '待定利息',
    'Available.Balance': '可用余额',
    'Active.Referral': '活跃推荐',
  },
};
