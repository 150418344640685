export default {
  en: {
    'Go.Back': 'Go Back',
    Balanсe: 'Balanсe',
    'Allow.balance': 'Allow balance',
    'Allow.amount': 'Allow amount',
    'Allow.Now': 'Allow now',
    'Do.not.close': 'Do not close this page while your transaction is being confirmed. This page will refresh shortly.',
  },
  ch: {
    'Go.Back': '返回',
    Balanсe: '余额',
    'Allow.balance': '允许余额',
    'Allow.amount': '允许数额',
    'Allow.Now': '允许',
    'Do.not.close': '请勿关闭页面， 耐心等待交易成功。交易成功后页面会自动刷新。',
  },
};
