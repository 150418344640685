// @flow
import React, { Component, type AbstractComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { close } from 'assets/images';

import styles from './Menu.module.scss';

// OwnProps refers to the props that were passed down by the parent.
type OwnProps = {||};

// Props created from OwnProps plus the props passed in by mapStateToProps and mapDispatchToProps
type Props = {|
  ...OwnProps,
  t: Function,
|};

export class Menu extends Component<Props> {
  render() {
    const { t } = this.props;
    return (
      <div className={styles.menu}>
        <div className={styles.header}>
          <Link type="button" className={styles.buttonClose} to="/">
            <img src={close} alt="close" className={styles.imgClose} />
          </Link>
        </div>

        <Link to="/" className={styles.link}>
          <div className={styles.item}>
            {t('Home')}
          </div>
        </Link>

        <div className={styles.line} />

        <div className={styles.item}>
          {t('Token.Sale')}
        </div>

        <div className={styles.line} />

        <div className={styles.item}>
          {t('Resources')}
        </div>

        <div className={styles.line} />

        <div className={styles.item}>
          {t('Contact.Us')}
        </div>

        <div className={styles.line} />

        <div className={styles.item}>
          {t('About.ESTT')}
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

// const mapDispatchToProps = dispatch => ({});

export default (connect(
  mapStateToProps,
  null,
)(withTranslation('menu')(Menu)): AbstractComponent<OwnProps>);
