export default {
  en: {
    'Are.you.sure': 'Are you sure you want to proceed without a referrer',
    'Your.referrer': 'Your referrer will receive a Direct Bonus of 0.05%.',
    'Go.Back': 'Go Back',
    Proceed: 'Proceed',
    Referrer: "Referrer's ID",
    'E.g.': 'E.g.',
  },
  ch: {
    'Are.you.sure': '您未输入推荐人资料，确定继续吗？',
    'Your.referrer': '您的推荐人将获得0.05%的直接奖金',
    'Go.Back': '返回',
    Proceed: '继续',
    Referrer: '推荐人ID',
    'E.g.': '例',
  },
};
