// @flow

import { takeLatest, call, put } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { getWeb3 } from 'utils/metamask';
import abi from 'constants/abi.json';
import constants from '../../constants';
import { CONNECT_REQUEST } from './constants';

import * as actions from './actions';
import * as actionsWarnning from '../Warning/actions';

function* connectSaga(): Saga<void> {
  try {
    // eslint-disable-next-line no-console
    console.log('connectSaga');
    // place your code here
    const web3 = yield call(getWeb3);
    const accounts = yield call(web3.eth.getAccounts);

    const contract = new web3.eth.Contract(abi, constants.addressToken);
    const exchangeAddress = yield call(contract.methods.exchangeAddress().call);

    const parentReferral = yield call(contract.methods.parentReferral(accounts[0]).call);

    const myReferrals = yield call(contract.methods.getMyReferrals().call, { from: accounts[0] });

    const holdersCounter = yield call(contract.methods.holdersCounter().call);

    yield put(actions.connectWalletSuccess({
      address: accounts[0],
      exchangeAddress,
      isUsedReferral: parentReferral !== '0x0000000000000000000000000000000000000000',
      myReferrals,
      holdersCounter,
    }));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('err', err.message);
    yield put(actions.connectWalletFail());
    yield put(actionsWarnning.setWarning(err.message));
  }
}

export default function* watchConnect(): Saga<void> {
  yield takeLatest(CONNECT_REQUEST, connectSaga);
}
