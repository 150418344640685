// @flow
import React, { Component, type AbstractComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BuySellWidget from 'components/BuySellWidget';

import * as actionsBalance from 'store/Balance/actions';
import * as actionsBuy from 'store/Buy/actions';
import * as actionsSell from 'store/Sell/actions';

import './BuySell.module.scss';

// OwnProps refers to the props that were passed down by the parent.
type OwnProps = {||};

// Props created from OwnProps plus the props passed in by mapStateToProps and mapDispatchToProps
type Props = {|
  ...OwnProps,
  isActiveBuy: boolean,
  isActiveSell: boolean,
  balance: string,
  balanceUsdt: string,
  setAmountForSellBuy: Function,
  buyShowModal: Function,
  sellShowModal: Function,
|};

export class BuySellComponent extends Component<Props> {
  render() {
    const {
      balance,
      balanceUsdt,
      isActiveBuy,
      isActiveSell,
      buyShowModal,
      sellShowModal,
      setAmountForSellBuy,
    } = this.props;
    return (
      <div className="buy-sell">
        <BuySellWidget
          balance={isActiveBuy ? balanceUsdt : balance}
          isActiveBuy={isActiveBuy}
          isActiveSell={isActiveSell}
          onBuy={() => {
            buyShowModal();
          }}
          onSell={() => {
            sellShowModal();
          }}
          amount={0}
          onChange={(value) => { setAmountForSellBuy(value); }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // isConnected: state.connect.isConnected,
  isActiveBuy: state.buy.isShowModal,
  // isVerify: state.buy.isVerify,
  isActiveSell: state.sell.isShowModal,
  // isVerifySell: state.sell.isVerify,
  balance: state.balance.balance,
  balanceUsdt: state.balance.balanceUsdt,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setAmountForSellBuy: actionsBalance.setAmountForSellBuy,
    // showModalDownloadMetaMask: actions.showModalDownloadMetaMask,
    buyShowModal: actionsBuy.buyShowModal,
    sellShowModal: actionsSell.sellShowModal,

  },
  dispatch,
);


export default (connect(
  mapStateToProps,
  mapDispatchToProps,
)(BuySellComponent): AbstractComponent<OwnProps>);
