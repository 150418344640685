// @flow
import React, { type Node } from 'react';
import Loader from 'react-loader-spinner';
// $FlowFixMe
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { withTranslation } from 'react-i18next';

import {
  copyImg,
  copyBlackImg,
} from 'assets/images';

import styles from './Button.module.scss';

type Props = {
  children: Node,
  color?: string,
  isSmall?: boolean,
  isCopy?: boolean,
  isLoading?: boolean,
  disabled?: boolean,
  hideTextWhenSmall?: boolean,
  tooltip?: string | null,
  onClick: Function,
  styleDefault?: Object,
  t: Function,
}

const getClassButton = (color: string = 'default', isLoading: boolean = false) => {
  if (isLoading) {
    return styles.buttonIsLoading;
  }
  if (color === 'primary') {
    return styles.buttonPrimary;
  }
  if (color === 'second') {
    return styles.buttonSecond;
  }
  if (color === 'red') {
    return styles.buttonRed;
  }
  if (color === 'outline') {
    return styles.buttonOutline;
  }
  return styles.button;
};

const styleSmall = {
  padding: '8px 15px', width: 'auto', fontSize: 16, borderRadius: 24,
};

const Button = ({
  children, color, onClick, isSmall, isCopy, tooltip, disabled, hideTextWhenSmall,
  isLoading, t, styleDefault,
}: Props) => (
  <div className={styles.containerButton}>
    <button
      className={getClassButton(color, isLoading)}
      type="button"
      onClick={onClick}
      style={isSmall ? styleSmall : styleDefault}
      data-tip={tooltip}
      disabled={disabled || isLoading}
    >
      {isCopy && (
        <img src={color === 'outline' || color === 'default' ? copyBlackImg : copyImg} alt="copy" className={styles.icon} />
      )}
      {!isLoading && (
        <div className={hideTextWhenSmall && styles.hideTextWhenSmall}>
          {children}
        </div>
      )}
      {isLoading && (
        <>
          <Loader
            type="TailSpin"
            color="#FFFFFF"
            height={18}
            width={30}
            timeout={0} // 3 secs
          />
          {t('Processing')}
        </>
      )}
    </button>
  </div>
);

Button.defaultProps = {
  color: 'default',
  isSmall: false,
  isLoading: false,
  tooltip: null,
  isCopy: false,
  disabled: false,
  hideTextWhenSmall: false,
  styleDefault: {},
};

export default withTranslation('button')(Button);
