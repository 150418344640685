// @flow
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Button from 'components/Button';
import { withTranslation } from 'react-i18next';

import { peopleDark } from 'assets/images';
import styles from './Input.module.scss';

type Props = {
  value: string,
  label?: string | null,
  withCoppy?: boolean,
  withCountPeople?: boolean,
  isValueCenter?: boolean,
  disabled?: boolean,
  placeholder?: string,
  countPeople?: number,
  onClick?: Function,
  onChange: Function,
  t: Function,
}
const Input = ({
  value,
  onClick,
  withCoppy,
  label,
  placeholder,
  onChange,
  withCountPeople,
  isValueCenter,
  disabled,
  countPeople,
  t,
}: Props) => (
  <div className={styles.containerInputWithLabel}>
    {label && (
      <div className={styles.label}>
        {label}
      </div>
    )}
    <div className={styles.containerInput}>
      {withCountPeople && (
        <div className={styles.containerCountPeople}>
          <img src={peopleDark} alt="peopleDark" className={styles.peopleDark} />
          {countPeople}
        </div>
      )}
      <input
        disabled={disabled}
        className={isValueCenter ? styles.inputCenter : styles.input}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        // style={{ textAlign: isValueCenter ? 'center' : 'left' }}
      />
      {withCoppy && (
        <div className={styles.containerRight}>
          <CopyToClipboard text={value}>
            <Button color="default" isSmall isCopy onClick={onClick}>
              {t('Copy')}
            </Button>
          </CopyToClipboard>
        </div>
      )}
    </div>
  </div>
);

Input.defaultProps = {
  label: null,
  placeholder: '',
  withCoppy: false,
  withCountPeople: false,
  isValueCenter: false,
  disabled: false,
  countPeople: 0,
  onClick: () => {},
};

export default withTranslation('input')(Input);
