// @flow
import React from 'react';
import {
  isMetaMaskLogin,
  isImTokenLogin,
  isTrustLogin,
} from 'utils/metamask';

import { withTranslation } from 'react-i18next';

import {
  logoImg,
  menuImg,
  // langImg,
  walletImg,
  metamaskImg,
  imTokenAvatar,
  trustPlatform,
  chLang,
  enLang,
} from 'assets/images';

import Button from 'components/Button';

import { Link } from 'react-router-dom';

import i18n from 'i18n';

import styles from './Header.module.scss';

type Props = {
  connectWallet: Function,
  isConnected: boolean,
  t: Function,
}
const Header = ({ connectWallet, isConnected, t }: Props) => (
  <div className={styles.containerHeader}>
    <img src={logoImg} alt="logo" className={styles.logoImg} />


    <div className={styles.containerRight}>
      <div className={styles.containerLinks}>
        <a href="http://app.estt.io/" target="_blank" rel="noopener noreferrer">{t('Token.Sale')}</a>
        <a href="http://www.estt.io/info" target="_blank" rel="noopener noreferrer">{t('Resources')}</a>
        <a href="http://www.estt.io/contact" target="_blank" rel="noopener noreferrer">
          {t('Contact.Us')}
        </a>
        <a href="http://www.estt.io/about" target="_blank" rel="noopener noreferrer">
          {t('About.ESTT')}
        </a>
      </div>
      <button type="button" className={styles.buttonLang} onClick={() => { i18n.changeLanguage(i18n.language === 'en' ? 'ch' : 'en'); }}>
        <img src={i18n.language === 'ch' ? enLang : chLang} alt="langImg" className={styles.langImg} />
      </button>
      <Link to="/menu" className={styles.link}>
        <img src={menuImg} alt="menu" className={styles.menuImg} />
      </Link>
      {!isConnected && (
        <Button isSmall onClick={() => connectWallet()} hideTextWhenSmall>
          <img src={walletImg} alt="wallet" className={styles.walletImg} />
          {t('Connect.wallet')}
        </Button>
      )}
      {isConnected && isMetaMaskLogin() && (
        <img src={metamaskImg} alt="metamask" className={styles.metamaskImg} />
      )}
      {isConnected && isImTokenLogin() && (
      <img src={imTokenAvatar} alt="imTokenAvatar" className={styles.imTokenAvatarImg} />
      )}
      {isConnected && isTrustLogin() && (
        <img src={trustPlatform} alt="trustPlatform" className={styles.trustPlatformImg} />
      )}
    </div>
  </div>
);

export default withTranslation('header')(Header);
