// @flow

import type { BuyActions } from './types';
import * as buyTypes from './constants';

export const buyProcess = (): BuyActions => ({
  type: buyTypes.BUY_REQUEST,
  payload: null,
});

export const buyProcessSuccess = (): BuyActions => ({
  type: buyTypes.BUY_SUCCESS,
  payload: null,
});

export const buyProcessFail = (): BuyActions => ({
  type: buyTypes.BUY_FAIL,
  payload: null,
});

export const verifyBuy = (): BuyActions => ({
  type: buyTypes.BUY_VERIFY,
  payload: null,
});

export const verifyBuySuccess = (): BuyActions => ({
  type: buyTypes.BUY_VERIFY_SUCCESS,
  payload: null,
});

export const verifyBuyGoBack = (): BuyActions => ({
  type: buyTypes.BUY_VERIFY_FAIL,
  payload: null,
});

export const buyShowModal = (): BuyActions => ({
  type: buyTypes.BUY_SHOW_MODAL,
  payload: null,
});

export const buyCloseModal = (): BuyActions => ({
  type: buyTypes.BUY_CLOSE_MODAL,
  payload: null,
});
